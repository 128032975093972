import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 120,
  },
}));

const authLv0 = ['', '現貨', '預留', '售出', '退胎'];

const StatusField = ({ value, onChange, sort, warning }) => {
  const userStatus = useSelector((state) => state.user);
  const classes = useStyles();
  let status = [];
  if (sort == 1) status = ['現貨'];
  if (sort == 2) {
    if (userStatus.info.authority === 0) {
      status = authLv0;
    } else if (userStatus.info.authority === 1) {
      status = [value, '售出'];
    } else if (userStatus.info.authority === 3) {
      status = [value, '退胎'];
    } else {
      status = [value];
    }
  }
  if (sort == 3) status = authLv0;
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        id="search-location"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={'狀態'}
      >
        {status.map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
      <InputLabel>{'狀態'}</InputLabel>
      <FormHelperText error={warning ? true : false}>
        {warning ? '已有出貨日期，不可設定此狀態' : '請選擇狀態'}
      </FormHelperText>
    </FormControl>
  );
};

StatusField.propTypes = {
  value: PropTypes.string,
  sort: PropTypes.number,
  warning: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default StatusField;
